import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBookEndpoints, getBookAIEndpoints, API_CONFIG } from '../config/api';

const API_BASE_URL = API_CONFIG?.book || process.env.REACT_APP_BOOK_API_URL || 'https://nxpg9gxt5k.execute-api.us-east-1.amazonaws.com/prod';
const AIContext = createContext();

// Special ID for outline mode conversations
const OUTLINE_MODE_ID = 'outline_mode_conversation';

const AIContextProvider = ({ children }) => {
  const [bookContext, setBookContext] = useState({ id: null, title: '' });
  const [currentChapter, setCurrentChapter] = useState({ id: null, content: '' });
  const [currentMode, setCurrentMode] = useState('outline'); // 'outline' or 'write'
  const [chapters, setChapters] = useState([]);
  const [conversationMemory, setConversationMemory] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [lastError, setLastError] = useState(null);
  const [chapterConversations, setChapterConversations] = useState({});
  const [chapterOutlines, setChapterOutlines] = useState([]);

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
  };

  const getUserId = () => localStorage.getItem('userId') || 'default-user';

  // Helper function to save the current conversation
  const saveCurrentConversation = () => {
    if (!currentChapter.id) return;
    
    console.log(`Explicitly saving conversation for chapter ${currentChapter.id}`);
    
    // Save the current conversation memory to the chapter-specific store
    setChapterConversations(prev => {
      const updatedConversations = {
        ...prev,
        [currentChapter.id]: {...conversationMemory} // Make a proper copy
      };
      
      // Immediately update the current conversation memory to ensure it persists
      setConversationMemory(updatedConversations[currentChapter.id]);
      
      return updatedConversations;
    });
  };

  const fetchBook = async (userId, bookId) => {
    if (!userId || !bookId) return;
    
    setIsLoading(true);
    setLastError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/books/${bookId}?userId=${userId}`, {
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch book: ${response.status}`);
      }
      
      const bookData = await response.json();
      setBookContext(bookData);
      
      // Extract chapters from the book object
      const bookChapters = bookData.chapters || [];
      const sortedChapters = [...bookChapters].sort((a, b) => (a.order || 0) - (b.order || 0));
      setChapters(sortedChapters);
      
      // Set first chapter as active if needed
      if (sortedChapters.length > 0 && !currentChapter.id) {
        const firstChapter = sortedChapters[0];
        setCurrentChapter({
          id: firstChapter.id,
          content: firstChapter.content || ''
        });
        
        // Load conversation for this chapter
        loadChapterConversation(firstChapter.id);
      }
    } catch (error) {
      console.error('Error fetching book:', error);
      setLastError('Failed to load book. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateBookContext = async (newContext) => {
    setBookContext(prev => {
      const updated = { ...prev, ...newContext };
      if (updated.id && (!prev.id || updated.id !== prev.id)) {
        fetchBook(getUserId(), updated.id);
      }
      return updated;
    });
  };

  const addChapter = async (bookId, chapterData) => {
    setIsLoading(true);
    setLastError(null);

    if (!bookId) {
      throw new Error('No book selected');
    }

    try {
      const userId = getUserId();
      console.log('Creating chapter with data:', { bookId, chapterData, userId });
      
      // Prepare the chapter data with an ID
      const chapterId = Date.now().toString();
      const newChapter = {
        id: chapterId,
        title: chapterData.title || 'Untitled Chapter',
        content: chapterData.content || '',
        description: chapterData.description || '',
        order: chapters.length + 1
      };
      
      // Use the special endpoint for embedded chapters
      const endpoint = `${API_BASE_URL}/books`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({
          userId: userId,
          bookId: bookId,
          chapter: newChapter,
          _updateType: 'chapter'
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to create chapter: ${response.status}`);
      }

      const bookData = await response.json();
      
      // The response should include the updated book with chapters array
      if (bookData && bookData.chapters) {
        setChapters(bookData.chapters);
        
        // Find our newly added chapter
        const addedChapter = bookData.chapters.find(ch => ch.id === chapterId);
        if (addedChapter) {
          setCurrentChapter({ id: addedChapter.id, content: addedChapter.content || '' });
        }
      }
      
      return true;
    } catch (error) {
      setLastError(error.message || 'Failed to create chapter');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateCurrentChapter = (chapterId, content, chapterOutlines = []) => {
    if (!chapterId) return;
    
    console.log(`Switching to chapter ${chapterId}`);
    
    // First, explicitly save conversation of current chapter if we're switching away from one
    if (currentChapter.id && currentChapter.id !== chapterId) {
      console.log(`Saving conversation for previous chapter ${currentChapter.id}`);
      saveCurrentConversation();
    }
    
    // Update the current chapter
    setCurrentChapter({ 
      id: chapterId, 
      content: content || ''
    });
    
    // If this is an actual chapter ID (not the outline mode ID), set mode to 'write'
    if (chapterId !== OUTLINE_MODE_ID) {
      setCurrentMode('write');
    }
    
    // Store chapter outlines in a separate state variable
    setChapterOutlines(chapterOutlines);
    
    // Set loading to true while we fetch chapter conversation history
    setIsLoading(true);
    
    // Load conversation specific to this chapter
    loadChapterConversation(chapterId);
    
    setIsLoading(false);
  };

  const clearConversationMemory = (chapterId) => {
    if (chapterId) {
      // Clear only the specified chapter's conversation
      setChapterConversations(prev => ({
        ...prev,
        [chapterId]: {}
      }));
      
      // If this is the current chapter, also clear the current memory
      if (currentChapter.id === chapterId) {
        setConversationMemory({});
      }
    } else {
      // Clear current chapter's conversation
      if (currentChapter.id) {
        setChapterConversations(prev => ({
          ...prev,
          [currentChapter.id]: {}
        }));
      }
      // Always clear the current memory
      setConversationMemory({});
    }
    setLastError(null);
  };

  const loadChapterConversation = (chapterId) => {
    if (!chapterId) return;
    
    console.log(`Loading conversation for chapter: ${chapterId}`);
    
    // Get conversation history for this chapter
    const chapterHistory = chapterConversations[chapterId] || {};
    
    // Log debug info about loaded conversation
    console.log('Found conversation data:', 
      Object.keys(chapterHistory).length > 0 ? 'Yes' : 'No',
      'For chapter:', chapterId
    );
    
    // Set as current conversation memory - create a new object to ensure reactivity
    setConversationMemory({...chapterHistory});
    
    // More descriptive logging
    if (Object.keys(chapterHistory).length > 0) {
      const messageCount = Object.values(chapterHistory)
        .filter(val => Array.isArray(val))
        .reduce((total, arr) => total + arr.length, 0);
      console.log(`Loaded ${messageCount} messages for chapter ${chapterId}`, chapterHistory);
    } else {
      console.log(`No existing conversation found for chapter ${chapterId}, starting fresh`);
    }
  };

  const getChapterContext = () => {
    if (!chapterOutlines || chapterOutlines.length === 0) return '';
    
    // Format chapter outlines into a readable string
    return `Chapter Outlines:
${chapterOutlines.map((ch, idx) => 
  `Chapter ${idx + 1}: ${ch.title}
${ch.description ? `Description: ${ch.description}` : 'No description'}`
).join('\n\n')}`;
  };

  const generateAIPrompt = (mode = 'write') => {
    const promptParts = [
      "You are a versatile AI assistant that helps with book writing and general conversation.",
      `Book Title: ${bookContext.title || 'Untitled Book'}`,
      bookContext.description ? `Book Description: ${bookContext.description}` : '',
      bookContext.genre ? `Genre: ${bookContext.genre}` : '',
      bookContext.targetAudience ? `Target Audience: ${bookContext.targetAudience}` : '',
      bookContext.tone ? `Tone: ${bookContext.tone}` : '',
      bookContext.summary ? `Summary: ${bookContext.summary}` : '',
    ];

    // Add mode-specific context
    if (mode === 'outline') {
      promptParts.push("\nYou're helping with book outlining. Focus on structure, plot, and character development.");
    } else {
      promptParts.push("\nYou're helping with chapter writing. Focus on content, descriptions, and dialogue.");
    }

    // Add DETAILED chapter outline information - improved for more context
    if (chapters && chapters.length > 0) {
      promptParts.push("\n\n===== COMPLETE BOOK OUTLINE =====");
      chapters.forEach((chapter, index) => {
        promptParts.push(`\nCHAPTER ${index + 1}: ${chapter.title}`);
        if (chapter.description) {
          // Include full chapter description
          promptParts.push(`Description: ${chapter.description}`);
        }
        
        // Add a brief snippet of content if available (first 100 chars)
        if (chapter.content && chapter.content.trim()) {
          const snippet = chapter.content.trim().substring(0, 100);
          promptParts.push(`Content Preview: ${snippet}${chapter.content.length > 100 ? '...' : ''}`);
        }
      });
      promptParts.push("\n===== END OF BOOK OUTLINE =====\n");
    }

    // Add current chapter context
    if (currentChapter.id) {
      const chapterIndex = chapters.findIndex(ch => ch.id === currentChapter.id);
      if (chapterIndex > -1) {
        promptParts.push(`\n===== CURRENTLY WORKING ON =====`);
        promptParts.push(`Chapter ${chapterIndex + 1}: ${chapters[chapterIndex].title}`);
        if (chapters[chapterIndex].description) {
          promptParts.push(`Chapter Description: ${chapters[chapterIndex].description}`);
        }
        promptParts.push(`=====`);
        
        // Include more content from the current chapter
        promptParts.push(`\nCurrent Chapter Content: ${currentChapter.content.substring(0, 800) || 'No content yet'}`);
        if (currentChapter.content && currentChapter.content.length > 800) {
          promptParts.push("...(content truncated)");
        }
      }
    }

    promptParts.push("\nRespond naturally to the user's message. If they ask about the book, use the context. If it's casual, chat freely.");
    return promptParts.filter(Boolean).join('\n');
  };

  const sendMessageToAI = async (message) => {
    if (!message.trim()) return;

    setIsLoading(true);
    setLastError(null);

    // Maximum number of retries
    const MAX_RETRIES = 2;
    let retryCount = 0;
    let lastError = null;

    try {
      const userMessage = { role: 'user', content: message, timestamp: new Date().toISOString() };
      
      // Determine the current context ID (either chapter ID or outline mode)
      const currentContextId = currentChapter.id || OUTLINE_MODE_ID;
      
      // Update conversation memory with user message immediately for better UX
      if (currentContextId) {
        // Store messages for this specific context
        setConversationMemory(prev => {
          // Get existing messages for this context or initialize empty array
          const contextMessages = prev[currentContextId] || [];
          
          // Create updated conversation for this context
          const updatedContextConvo = {
          ...prev,
            [currentContextId]: [userMessage, ...contextMessages]
          };
          
          // Also update the chapterConversations to persist this
          setChapterConversations(prevConvos => ({
            ...prevConvos,
            [currentContextId]: updatedContextConvo
          }));
          
          return updatedContextConvo;
        });
      }

      const context = generateAIPrompt();
      const userId = getUserId();
      
      // Check if we need to use a different endpoint
      const endpoint = `${API_BASE_URL}/ai/conversations`;
      console.log('Attempting to send message to AI service:', { endpoint, userId, mode: currentMode });
  
      const requestBody = { 
        message, 
        context, 
        userId,
        // Add timestamp to help with debugging
        timestamp: new Date().toISOString(),
        // Add mode to help backend know what kind of response to generate
        mode: currentMode
      };

      // Log request details for debugging
      console.log('AI request payload:', JSON.stringify(requestBody, null, 2));

      // Retry loop
      while (retryCount <= MAX_RETRIES) {
        try {
          // If this isn't the first attempt, log that we're retrying
          if (retryCount > 0) {
            console.log(`Retrying AI request (attempt ${retryCount} of ${MAX_RETRIES})...`);
          }

          // Update the request with more complete error handling
          const controller = new AbortController();
          // Increase timeout for each retry
          const timeoutMs = 20000 + (retryCount * 5000); // 20s, 25s, 30s
          const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody),
            signal: controller.signal
      });
          
          clearTimeout(timeoutId);

      if (!response.ok) {
        // Try to get more details about the error
        const errorText = await response.text();
        throw new Error(`AI request failed (${response.status}): ${errorText || 'No response details'}`);
      }

      // Parse the response
      const data = await response.json();
      console.log('Raw AI response:', data);
      
      if (!data || !data.response) {
        throw new Error('Invalid or empty response from AI service');
      }

      // Create AI message and update conversation memory
      const aiMessage = { 
        role: 'assistant', 
        content: data.response, 
        timestamp: new Date().toISOString() 
      };
      
      // Update conversation memory with AI response
          if (currentContextId) {
            setConversationMemory(prev => {
              // Get existing messages for this context
              const contextMessages = prev[currentContextId] || [];
              
              // Create updated conversation for this context
              const updatedContextConvo = {
          ...prev,
                [currentContextId]: [aiMessage, ...contextMessages]
              };
              
              // Also update the chapterConversations to persist this
              setChapterConversations(prevConvos => ({
                ...prevConvos,
                [currentContextId]: updatedContextConvo
              }));
              
              return updatedContextConvo;
            });
      }
      
      return data.response;
        } catch (fetchError) {
          // Track the last error
          lastError = fetchError;
          
          // Check if this is an abort error
          if (fetchError.name === 'AbortError') {
            console.warn(`AI request timed out (attempt ${retryCount + 1} of ${MAX_RETRIES + 1})`);
            
            // If we've reached max retries, throw the timeout error
            if (retryCount >= MAX_RETRIES) {
              throw new Error(`Request timed out after ${MAX_RETRIES + 1} attempts. The AI service might be unavailable.`);
            }
            
            // Otherwise, increment retry count and continue the loop
            retryCount++;
            
            // Add a slight delay before retrying (exponential backoff)
            await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
            continue;
          }
          
          // For other errors, throw immediately
          throw fetchError;
        }
      }
      
      // If we get here, it means all retries failed with a timeout
      throw lastError || new Error('Failed to connect to AI service after multiple attempts');
    } catch (error) {
      console.error('AI service error:', error);
      setLastError(error.message || 'An error occurred connecting to the AI service');
      
      // Create an error message to display to the user
      const errorMessage = { 
        role: 'assistant', 
        content: `Error: ${error.message || 'Failed to connect to AI service'}`, 
        isError: true, 
        timestamp: new Date().toISOString() 
      };
      
      // Add error message to conversation memory
      if (currentChapter.id) {
        setConversationMemory(prev => {
          const contextMessages = prev[currentChapter.id] || [];
          return {
          ...prev,
            [currentChapter.id]: [errorMessage, ...contextMessages]
          };
        });
      }
      
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Add these two useEffects for localStorage persistence of conversations
  
  // Load conversations from localStorage on initial load
  useEffect(() => {
    // Only attempt to load if we have a bookId
    if (bookContext?.id) {
      try {
        const savedConversations = localStorage.getItem(`book_${bookContext.id}_conversations`);
        if (savedConversations) {
          const parsed = JSON.parse(savedConversations);
          console.log('Loaded saved conversations from localStorage:', 
            Object.keys(parsed).length, 'chapters');
          setChapterConversations(parsed);
        }
      } catch (error) {
        console.error('Error loading conversations from localStorage:', error);
      }
    }
  }, [bookContext?.id]); // Only run when book ID changes

  // Save conversations to localStorage when they change
  useEffect(() => {
    // Only save if we have conversations and a bookId
    if (Object.keys(chapterConversations).length > 0 && bookContext?.id) {
      try {
        localStorage.setItem(
          `book_${bookContext.id}_conversations`, 
          JSON.stringify(chapterConversations)
        );
        console.log('Saved conversations to localStorage:', 
          Object.keys(chapterConversations).length, 'chapters');
      } catch (error) {
        console.error('Error saving conversations to localStorage:', error);
      }
    }
  }, [chapterConversations, bookContext?.id]); // Run when conversations or book ID changes

  useEffect(() => {
    console.log('AIContext state updated:', {
      bookContext,
      currentChapter,
      chaptersLength: chapters.length,
      conversationMemoryLength: conversationMemory.getLength 
        ? conversationMemory.getLength() 
        : Array.isArray(conversationMemory) 
          ? conversationMemory.length 
          : Object.values(conversationMemory).reduce((total, messages) => total + (Array.isArray(messages) ? messages.length : 0), 0),
      isLoading,
      lastError,
    });
  }, [bookContext, currentChapter, chapters, conversationMemory, isLoading, lastError]);

  // Make sure conversationMemory is always array-compatible
  if (conversationMemory && !Array.isArray(conversationMemory)) {
    // Create a safe slice method
    conversationMemory.slice = function(start, end) {
      // Determine the current context ID (either chapter ID or outline mode)
      const currentContextId = currentChapter?.id || OUTLINE_MODE_ID;
      let messageArray = [];
      
      if (currentContextId && Array.isArray(this[currentContextId])) {
        // Get messages for current context (chapter or outline mode)
        messageArray = this[currentContextId];
      } else {
        // Try to find any available message array
        const firstArray = Object.values(this)
          .find(val => Array.isArray(val) && val.length > 0);
        
        if (firstArray) {
          messageArray = firstArray;
        } else {
          // Last resort - check if we have any arrays at all
          const allArrays = Object.values(this)
            .filter(val => Array.isArray(val));
          
          if (allArrays.length > 0) {
            // Combine all arrays
            messageArray = allArrays.flat();
          }
        }
      }
      
      // Make sure we have a valid array before slicing
      if (!Array.isArray(messageArray)) {
        console.warn('Could not find message array in conversation memory');
        return [];
      }
      
      return messageArray.slice(start, end);
    };
    
    // Add a getLength method instead of trying to define a length property
    conversationMemory.getLength = function() {
      const currentContextId = currentChapter?.id || OUTLINE_MODE_ID;
      
      if (currentContextId && Array.isArray(this[currentContextId])) {
        return this[currentContextId].length;
      }
      
      // Count messages across all contexts
      return Object.values(this)
        .filter(val => Array.isArray(val))
        .reduce((total, arr) => total + arr.length, 0);
    };
  }

  // Update switchToChapter to explicitly save & load conversations
  const switchToChapter = (chapterId) => {
    if (!chapterId) return;
    
    // Find the chapter
    const chapter = chapters.find(ch => ch.id === chapterId);
    if (!chapter) {
      console.error(`Chapter ${chapterId} not found`);
      return;
    }
    
    console.log(`Switching to chapter: ${chapter.title}`);
    
    // Save current conversation first
    saveCurrentConversation();
    
    // Update current chapter
    setCurrentChapter({
      id: chapterId,
      content: chapter.content || ''
    });
    
    // Make sure we're in write mode if not already
    if (currentMode !== 'write') {
      setCurrentMode('write');
    }
    
    // Load this chapter's specific conversation
    loadChapterConversation(chapterId);
  };

  // Update switchMode function to properly handle conversation saving/loading
  const switchMode = (mode, chapterId = null) => {
    if (mode !== 'outline' && mode !== 'write') {
      console.error('Invalid mode: must be "outline" or "write"');
      return;
    }
    
    console.log(`Switching to ${mode} mode from ${currentMode}`);
    
    // Save current conversation before switching
    saveCurrentConversation();
    
    // Only update if actually changing modes
    if (mode !== currentMode) {
      setCurrentMode(mode);
      
      if (mode === 'outline') {
        // When switching to outline mode, load the outline conversation
        setCurrentChapter({
          id: OUTLINE_MODE_ID,
          content: ''
        });
        
        // Load outline conversation
        loadChapterConversation(OUTLINE_MODE_ID);
        
      } else if (mode === 'write' && chapterId) {
        // When switching to write mode with a specific chapter
        const chapter = chapters.find(ch => ch.id === chapterId);
        if (chapter) {
          setCurrentChapter({
            id: chapterId,
            content: chapter.content || ''
          });
          loadChapterConversation(chapterId);
        }
      } else if (mode === 'write' && chapters.length > 0) {
        // Default to first chapter if no specific chapter is provided
        const firstChapter = chapters[0];
        setCurrentChapter({
          id: firstChapter.id,
          content: firstChapter.content || ''
        });
        loadChapterConversation(firstChapter.id);
      }
    } else if (mode === 'write' && chapterId && currentChapter.id !== chapterId) {
      // We're already in write mode but switching chapters
      switchToChapter(chapterId);
    }
  };

  const contextValue = {
    bookContext,
    updateBookContext,
    currentChapter,
    updateCurrentChapter,
    currentMode,
    switchMode,
    chapters,
    addChapter,
    conversationMemory,
    clearConversationMemory,
    chapterConversations,
    loadChapterConversation,
    generateAIPrompt,
    sendMessageToAI,
    isLoading,
    lastError,
    getChapterContext,
    switchToChapter,
    saveCurrentConversation
  };

  return <AIContext.Provider value={contextValue}>{children}</AIContext.Provider>;
};

const useAIContext = () => {
  const context = useContext(AIContext);
  if (!context) throw new Error('useAIContext must be used within an AIContextProvider');
  return context;
};

export { AIContextProvider, useAIContext };
export default AIContextProvider;