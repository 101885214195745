import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/AuthContext';
import { AudioProvider } from './context/AudioContext';

// Direct import the AIContextProvider (not lazy-loaded)
import AIContextProvider from './biglio-book/AIContextManager';

// Import ProtectedRoute
const ProtectedRoute = React.lazy(() => import('./biglio-book/components/ProtectedRoute'));

// Lazy-load components
const Navbar = React.lazy(() => import('./components/Navbar'));
const HomePage = React.lazy(() => import('./components/HomePage'));
const SignInModal = React.lazy(() => import('./components/SignInModal'));
const CreatePage = React.lazy(() => import('./components/CreatePage'));
const VoicesRedirect = React.lazy(() => import('./components/VoicesRedirect'));
const ChannelPage = React.lazy(() => import('./components/ChannelPage'));
const AudioPlayerPage = React.lazy(() => import('./components/AudioPlayerPage'));
const Channels = React.lazy(() => import('./components/Channels'));
const BlogList = React.lazy(() => import('./components/blog/BlogList'));
const BlogPost = React.lazy(() => import('./components/blog/BlogPost'));
const BlogUserPosts = React.lazy(() => import('./components/blog/BlogUserPosts'));
const NotFoundPage = React.lazy(() => import('./components/NotFoundPage'));
const MediaKit = React.lazy(() => import('./components/MediaKit'));
const Footer = React.lazy(() => import('./components/Footer'));
const BiglioChat = React.lazy(() => import('./biglio-chat/BiglioChat'));

// Book-related components
const BookCreator = React.lazy(() => import('./biglio-book/BookCreator'));
const BookEditor = React.lazy(() => import('./biglio-book/BookEditor'));
const OutlineCreator = React.lazy(() => import('./biglio-book/OutlineCreator'));
const BookDashboard = React.lazy(() => import('./biglio-book/BookDashboard'));

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialView, setInitialView] = useState('signin');
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const location = useLocation();

  const openModal = (view = 'signin') => {
    setInitialView(view);
    setIsModalOpen(true);
  };
  
  const closeModal = () => setIsModalOpen(false);

  const toggleCreateForm = () => {
    setIsCreateFormOpen(!isCreateFormOpen);
  };

  // Check URL parameters for auth-related actions
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shouldOpenSignIn = params.get('signin') === 'true';
    const redirectPath = params.get('redirect');
    
    if (shouldOpenSignIn) {
      openModal('signin');
      
      // Store redirect path if present
      if (redirectPath) {
        sessionStorage.setItem('authRedirect', redirectPath);
      }
    }
  }, [location]);

  return (
    <AuthProvider>
      <AudioProvider>
        {/* Important: Keep AIContextProvider outside of Suspense */}
        <AIContextProvider>
          <div className="app-container bg-gray-900 min-h-screen">
            <Suspense fallback={<div>Loading...</div>}>
              <Navbar
                onSignInClick={() => openModal('signin')}
                onSignUpClick={() => openModal('signup')}
                isCreateFormOpen={isCreateFormOpen}
                setIsCreateFormOpen={setIsCreateFormOpen}
              />
              <main className="main-content text-white">
                <Routes>
                  <Route path="/" element={<HomePage openModal={openModal} />} />
                  <Route path="/voices" element={<VoicesRedirect />} />
                  <Route path="/channel/:username" element={<ChannelPage />} />
                  <Route
                    path="/audio-player"
                    element={<AudioPlayerPage openModal={() => openModal('signup')} />}
                  />
                  <Route
                    path="/s/:shortCode"
                    element={<AudioPlayerPage openModal={() => openModal('signup')} />}
                  />
                  <Route path="/channels" element={<Channels />} />
                  <Route path="/blog" element={<BlogList />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/blog/user/:username" element={<BlogUserPosts />} />
                  <Route path="/media-kit" element={<MediaKit />} />
                  <Route path="/biglio-chat" element={<BiglioChat />} />
                  
                  {/* Protected Book routes */}
                  <Route path="/create-book" element={
                    <Suspense fallback={<div>Loading book dashboard...</div>}>
                      <ProtectedRoute>
                        <BookDashboard />
                      </ProtectedRoute>
                    </Suspense>
                  } />
                  <Route path="/create-new-book" element={
                    <Suspense fallback={<div>Loading book creator...</div>}>
                      <ProtectedRoute>
                        <BookCreator />
                      </ProtectedRoute>
                    </Suspense>
                  } />
                  <Route path="/editor/:bookId" element={<BookEditor />} />
                  <Route path="/outline/:bookId" element={<BookEditor />} />
                  
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </main>
              <Footer />
              <SignInModal isOpen={isModalOpen} onClose={closeModal} initialView={initialView} />
            </Suspense>
          </div>
          <Toaster
            position="bottom-center"
            toastOptions={{
              style: {
                background: '#333',
                color: '#fff',
              },
            }}
          />
        </AIContextProvider>
      </AudioProvider>
    </AuthProvider>
  );
};

export default App;