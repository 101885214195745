import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from 'react-dom';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { BrowserRouter } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

Amplify.configure(config);

// For react-snap prerendering
const rootElement = document.getElementById('root');

// Check if we need to hydrate (for react-snap SSR)
if (rootElement.hasChildNodes() && window.REACT_SNAP) {
  // If it's a hydration (SSR), use hydrate from react-dom
  hydrate(
    <React.StrictMode>
      <BrowserRouter future={{ v7_relativeSplatPath: true }}>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
} else {
  // For normal rendering, use the modern React 18 createRoot API
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter future={{ v7_relativeSplatPath: true }}>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

// This is for modern React 18 usage, but we're using the older methods above for react-snap compatibility
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter future={{ v7_relativeSplatPath: true }}>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );