// config/api.js
import awsConfig from '../aws-exports';
import axios from 'axios';

// Use environment variables for all endpoints with fallback values
export const STREAMING_CLOUDFRONT_DOMAIN = process.env.REACT_APP_STREAMING_CLOUDFRONT_DOMAIN || 'https://d2ffp0xgyo62dc.cloudfront.net';

const API_CONFIG = {
    suggestions: process.env.REACT_APP_SUGGESTIONS_URL || 'https://2uhtkxnckk.execute-api.us-east-1.amazonaws.com/prod/generate-suggestions',
    createAudio: process.env.REACT_APP_CREATE_AUDIO_URL || 'https://wq0vbglp42.execute-api.us-east-1.amazonaws.com/prod/create-audio',
    chat: process.env.REACT_APP_CHAT_URL || 'https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat',
    profile: process.env.REACT_APP_PROFILE_URL || 'https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile',
    profileImageUpload: process.env.REACT_APP_PROFILE_IMAGE_URL || 'https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile/image-upload',
    playlist: process.env.REACT_APP_PLAYLIST_URL || 'https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod',
    audioFiles: process.env.REACT_APP_AUDIO_FILES_URL || 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod',
    audioCategories: process.env.REACT_APP_AUDIO_CATEGORIES_URL || 'https://oovmxcx0n0.execute-api.us-east-1.amazonaws.com/v1',
    public: process.env.REACT_APP_PUBLIC_API_URL || 'https://utru71xp8h.execute-api.us-east-1.amazonaws.com/prod',
    private: process.env.REACT_APP_PRIVATE_API_URL || 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod',
    audioUpdate: process.env.REACT_APP_AUDIO_UPDATE_URL || 'https://n93z0uu1pe.execute-api.us-east-1.amazonaws.com/prod/audio-file',
    branding: process.env.REACT_APP_BRANDING_AUDIO_URL || 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/biglio_open_v1.mp3',
    shortUrl: process.env.REACT_APP_SHORT_URL || 'https://yqs259wu9i.execute-api.us-east-1.amazonaws.com/prod/shorten',
    blog: process.env.REACT_APP_BLOG_URL || 'https://84fhuuluo6.execute-api.us-east-1.amazonaws.com/prod/blog',
    // Book API endpoints
    book: process.env.REACT_APP_BOOK_API_URL || 'https://nxpg9gxt5k.execute-api.us-east-1.amazonaws.com/prod'
};

// S3 URLs
const S3_CONFIG = {
    defaultAvatar: process.env.REACT_APP_DEFAULT_AVATAR_URL || 'https://s3.us-west-2.amazonaws.com/biglio.com/static/media/default_avatar.png',
    audioStorage: process.env.REACT_APP_AUDIO_STORAGE_URL || 'https://storage-audio-file.s3.amazonaws.com/audio',
    voiceSamples: {
        andrew: process.env.REACT_APP_VOICE_ANDREW || 'voice-samples/voice-sample-andrew.mp3',
        ava: process.env.REACT_APP_VOICE_AVA || 'voice-samples/voice-sample-ava.mp3',
        ana: process.env.REACT_APP_VOICE_ANA || 'voice-samples/voice-sample-ana.mp3',
        ryan: process.env.REACT_APP_VOICE_RYAN || 'voice-samples/voice-sample-ryan.mp3'
    }
};

// Create API Client
const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Existing endpoint getters
export const getSuggestionsEndpoint = () => API_CONFIG.suggestions;
export const getCreateAudioEndpoint = () => API_CONFIG.createAudio;
export const getChatEndpoint = () => API_CONFIG.chat;
export const getProfileEndpoint = () => `${API_CONFIG.profile}`;
export const getPlaylistEndpoint = () => API_CONFIG.playlist;
export const getAudioFilesEndpoint = () => API_CONFIG.audioFiles;
export const getAudioCategoriesEndpoint = () => API_CONFIG.audioCategories;
export const getShortUrlEndpoint = () => API_CONFIG.shortUrl;
export const getShortUrlLookupEndpoint = (shortCode) => `${API_CONFIG.shortUrl}/${shortCode}`;
export const getDefaultAvatarUrl = () => S3_CONFIG.defaultAvatar;
export const getAudioStorageUrl = () => S3_CONFIG.audioStorage;
export const getDemoAudioUrl = () => process.env.REACT_APP_DEMO_AUDIO_URL || 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/1739848286168-en-US-AndrewNeural.mp3';
export const getVoiceSampleUrl = (voiceName) => {
    const filename = S3_CONFIG.voiceSamples[voiceName.toLowerCase()];
    return `${S3_CONFIG.audioStorage}/${filename}`;
};
export const getBrandingAudioUrl = () => API_CONFIG.branding;
export const getPublicApiEndpoint = () => API_CONFIG.public;
export const getPrivateApiEndpoint = () => API_CONFIG.private;
export const getPlaylistApiEndpoint = () => API_CONFIG.playlist;
export const getAudioUpdateEndpoint = () => API_CONFIG.audioUpdate;
export const getProfileImageUploadEndpoint = () => API_CONFIG.profileImageUpload;

// Blog-specific endpoints
export const getBlogEndpoints = () => ({
    getAllPosts: (page = 1) => `${API_CONFIG.blog}?page=${page}`,
    getPost: (slug) => `${API_CONFIG.blog}/${slug}`,
    getPostsByCategory: (category, page = 1) => `${API_CONFIG.blog}/category/${category}?page=${page}`,
    getPostsByUser: (username, page = 1) => `${API_CONFIG.blog}/user/${username}?page=${page}`,
    getRecentPosts: () => `${API_CONFIG.blog}/recent`
});

// Book-specific endpoints
export const getBookEndpoints = () => ({
    getBooks: (userId) => `${API_CONFIG.book}/books?userId=${userId}`,
    getBook: (bookId, userId) => `${API_CONFIG.book}/books/${bookId}?userId=${userId}`,
    createOrUpdateBook: () => `${API_CONFIG.book}/books`,
    deleteBook: (bookId, userId) => `${API_CONFIG.book}/books/${bookId}?userId=${userId}`,
    getChapters: (bookId) => `${API_CONFIG.book}/books/${bookId}/chapters`,
    getChapter: (bookId, chapterId) => `${API_CONFIG.book}/books/${bookId}/chapters/${chapterId}`,
    createOrUpdateChapter: (bookId) => `${API_CONFIG.book}/books/${bookId}/chapters`,
    deleteChapter: (bookId, chapterId, userId) => `${API_CONFIG.book}/books/${bookId}/chapters/${chapterId}?userId=${userId}`
});

// AI-specific endpoints for book writing
export const getBookAIEndpoints = () => ({
    startConversation: () => `${API_CONFIG.book}/ai/conversations`,
    continueConversation: (conversationId) => `${API_CONFIG.book}/ai/conversations/${conversationId}`,
    getConversationHistory: (conversationId) => `${API_CONFIG.book}/ai/conversations/${conversationId}`
});

// AWS Config
const AWS_CONFIG = {
    userPoolId: process.env.REACT_APP_USER_POOL_ID || awsConfig.userPoolId,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || awsConfig.userPoolWebClientId
};

export const getAwsConfig = () => ({
    UserPoolId: AWS_CONFIG.userPoolId,
    ClientId: AWS_CONFIG.userPoolWebClientId
});

export const getStreamingUrl = (file) => {
    return `${STREAMING_CLOUDFRONT_DOMAIN}/stream/${file.userId}/${file.filename}/master_high.m3u8`;
};

export { apiClient, API_CONFIG, S3_CONFIG, AWS_CONFIG };